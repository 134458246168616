import i18n from '@/libs/i18n';
import Axios from '../../libs/strataIns';
export default {
  state: {
    settings: {},
  },
  getters: {
    settings: (state) => state.settings,
  },
  mutations: {
    setSettings(state, payload) {
      document.title = payload.AppName&&typeof payload.AppName=='object'?payload.AppName[i18n.locale]:payload.AppName
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = payload.favicon
      state.settings = { ...payload };
    },

  },

  actions: {
    getSettings({ commit }, payload = {}) {
      return new Promise((resolve, reject) => {
        Axios.post(`${payload.public?'':'/admin'}/settings`, {
          locale: payload.locale
        })
          .then(res => {
            if (res.data.results != null) {
              commit('setSettings', res.data.results);
              resolve(res);
            } else {
              reject(res);
            }
          }).catch(err => {
            reject(err);
          });
      });
    },
    updateSettings(_cont, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/settings/save`, {
          settings: {
            ...payload
          },
        })
          .then(res => {
            if (res.data.results != null) {
              resolve(res);
            } else {
              reject(res);
            }
          }).catch(err => {
            reject(err);
          });
      });
    },

  }
};