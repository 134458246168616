import Axios from '../../libs/strataIns';

export default {
  state: {
    usersMeta: {},
    users: [],
    filteredUsers: [],
    filteredSellers: [],
    filteredUser: {},
    // orderStatus: {},
    addresses: [],
  },

  getters: {
    filteredUsers: (state) => state.filteredUsers,
    filteredSellers: (state) => state.filteredSellers,
    usersMeta: (state) => state.usersMeta,
    users: (state) => state.users,
    filteredUser: (state) => state.filteredUser,
    addresses: (state) => state.addresses,
  },

  mutations: {
    setUser(state, payload) {
      state.filteredUser = {...payload}
    },
    setAddresses(state, payload) {
      state.addresses = [...payload]
    },
    setFilteredUsers(state, payload) {
      state.filteredUsers = [...payload]
    },
    setSupplier(state, payload) {
      state.supplier = {...payload}
    },
    setSellers(state, payload) {
      state.filteredSellers = [...payload]
    },
    removeUser(state, payload) {
      let index = state.filteredUsers.findIndex(el => el.id == payload);
      state.filteredUsers.splice(index, 1);
    },
    setUsersData(state, payload) {
      state.usersMeta = {...payload.meta};
      state.users = [...payload.results];
    },
    setFilteredUser(state, payload) {
      state.filteredUser = {...payload}
    }

  },

  actions: {
    getUsers({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/accounts`, {}, {
          params: {
            page: payload.page,
            role: payload.role,
            keywords: payload.keywords,
            export: payload.export,
            limit: payload.limit
          }
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setUsersData', res.data);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    getFilteredUsers({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/accounts`, {
          
        }, {
          params: {
            id: payload.id,
            perm: payload.perm,
            role: payload.role,
            perms: payload.permissions,
            keywords: payload.keywords,
            page: payload.page,
            export: payload.export,
            limit: payload.limit
          }
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setFilteredUsers', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 

    getUser({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/accounts/show/${payload}`, {
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setFilteredUser', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    },

    getAddresses({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/products/addresses`, {
          user_id: payload.client_id
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setAddresses', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    },
    createUser({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/accounts/save`, {
          id: payload.id,
          name: payload.name,
          email: payload.email,
          phone: payload.phone,
          password: payload.password,
          role_id: payload.role_id,
          status: payload.status
        })
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteUser({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`admin/accounts/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeUser', payload)
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    
  }
}