import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueCryptojs from "vue-cryptojs";
import i18n from "@/libs/i18n";

import router from "./router";
import store from "./store";
import App from "./App.vue";

import storage from "./libs/storage";
import helper from "./libs/storage/helper";
import permissions from "./libs/storage/permissions";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
import "@/libs/vue-select";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueCryptojs);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
// Composition API
Vue.use(VueCompositionAPI);
Vue.component("quill-editor", quillEditor);
// import core styles
import { Slider } from "vue-color";
Vue.component("colorpicker", Slider);
require("@core/scss/core.scss");
require("@core/scss/vue/libs/vue-sweetalert.scss");
require("@core/scss/vue/libs/vue-select.scss");

// import assets styles
require("@/assets/scss/style.scss");

// import mixins
Vue.mixin(storage);
Vue.mixin(helper);
Vue.mixin(permissions);

// import components

window.cleanObject = function (obj) {
  for (let key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      (obj[key] === null ||
        obj[key] === undefined ||
        obj[key] === "" ||
        (Array.isArray(obj[key]) && obj[key].length === 0) ||
        (typeof obj[key] === "object" && Object.keys(obj[key]).length === 0))
    ) {
      delete obj[key];
    }
  }
  return obj;
};
Vue.config.productionTip = false;
Vue.config.disableNoTranslationWarning = true;
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
