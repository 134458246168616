import Axios from '../../libs/strataIns';

export default {
  state: {
    labels: [],
    // offer: {},
    
    // ordersStatus: [],
    // orderStatus: {},
  },

  getters: {
    labels: (state) => state.labels,
    // offer: (state) => state.offer,
    // ordersStatus: (state) => state.ordersStatus,
    // orderStatus: (state) => state.orderStatus,
  },

  mutations: {
    setlabels(state, payload) {
      state.labels = [...payload]
    },
    removeLabel(state, payload) {
      let index = state.labels.findIndex(el => el.id == payload);
      state.labels.splice(index, 1);
    }
  },

  actions: {
    getLabels({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/labels`, {
          id: payload.id,
          keywords: payload.keywords,
          categorys_id: payload.driver_id,
          status: payload.status,
          page: payload.page,
        })
        .then(res => {
          if(res.data.results != null) {
            commit('setlabels', res.data.results);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    createLabel({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`/admin/labels/save`, {
          id: payload.id,
          titles: {
            ar: payload.titles.ar,
            en: payload.titles.ar,
          },
          color: payload.color,
          image_base64: payload.base64 ? {...payload.base64[0]} : undefined,
          status: payload.status
        })
        .then(res => {
          if(res.data.results != null) {
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    deleteLabel({commit}, payload) {
      return new Promise ((resolve, reject) => {
        Axios.post(`admin/labels/delete/${payload}`, {})
        .then(res => {
          if(res.data.results != null) {
            commit('removeLabel', payload);
            resolve(res);
          }else {
            reject(res)
          }
        }).catch(err => {
          reject(err)
        });
      })
    }, 
    
  }
}