import i18n from '@/libs/i18n';
import Axios from '../../libs/strataIns';

export default {
  state: {
    categories: [],
    category: {},

    // ordersStatus: [],
    // orderStatus: {},
  },

  getters: {
    categories: (state) => state.categories,
    category: (state) => state.category,
    // ordersStatus: (state) => state.ordersStatus,
    // orderStatus: (state) => state.orderStatus,
  },

  mutations: {
    setCategories(state, payload) {
      state.categories = [...payload]
    },
    setCategory(state, payload) {
      state.category = { ...payload }
    },
    addCategory(state, payload) {
      state.categories.push(payload);
    },
    updateCategory(state, payload) {
      let index = state.categories.findIndex(el => el.id == payload.id);
      state.categories.splice(index, 1, payload);
    },
    removeCategory(state, payload) {
      let index = state.categories.findIndex(el => el.id == payload);
      state.categories.splice(index, 1);
    }

  },

  actions: {
    getCategories({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/categories`, {
          keywords: payload.keywords,
          page: payload.page,
        },
          {
            headers: {
              'Content-Language': i18n.locale
            }
          })
          .then(res => {
            if (res.data.results != null) {
              commit('setCategories', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    getCategory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/categories/show/${payload}`, {
        })
          .then(res => {
            if (res.data.results != null) {
              commit('setCategory', res.data.results);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    createCategory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/categories/save`, {
          id: payload.id,
          titles: payload.titles,
          descriptions: payload.descriptions,
          parent_id: payload.parent_id,
          status: payload.status,
          featured: payload.featured,
          ord: payload.ord,
          icon_base64: payload.base64,
          icon_url: payload.base64,
        })
          .then(res => {
            if (res.data.results != null) {
              if (payload.id) {
                commit('updateCategory', res.data.results);
              } else {
                commit('addCategory', res.data.results);
              }
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },
    deleteCategory({ commit }, payload) {
      return new Promise((resolve, reject) => {
        Axios.post(`/admin/categories/delete/${payload}`, {})
          .then(res => {
            if (res.data.results != null) {
              commit('removeCategory', payload);
              resolve(res);
            } else {
              reject(res)
            }
          }).catch(err => {
            reject(err)
          });
      })
    },

  }
}